import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const SinglePage = props => {
    const {
        pageContext: { id, postId, title, content, excerpt },
    } = props

    return (
        <Layout>
            <SEO title={title} description={excerpt} />
            <article
                data-id={id}
                id={`post-${postId}`}
                className={`post-${postId} page type-page status-publish hentry entry`}
            >
                <header className="page-header entry-header">
                    <h1 className="page-title entry-title">{title}</h1>
                </header>

                <div className="page-content">
                    <div
                        className="entry-content"
                        dangerouslySetInnerHTML={{ __html: content }}
                    ></div>
                </div>
                {/* .entry-content */}
            </article>
            {/* #post-${ID} */}
        </Layout>
    )
}

export default SinglePage
